var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mileStatus" }, [
    _c(
      "ul",
      _vm._l(_vm.visilbeItems, function (item, index) {
        return _c("li", { key: index, class: [item.bg ? item.bg : ""] }, [
          _vm._v(" " + _vm._s(item.label) + " "),
          _c("span", [_vm._v(_vm._s(item.value))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }