// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/dashboard/ico-tit2.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/dashboard/ico-tit3.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".mileStatus[data-v-65be8c7a] {\n  margin-left: 120px;\n  margin-top: -10px;\n}\n.mileStatus ul li[data-v-65be8c7a] {\n  height: 35px;\n  line-height: 35px;\n  font-size: 14px;\n  border-bottom: 1px dotted #01504c;\n  padding-right: 30px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left center;\n  background-size: 6px;\n  padding-left: 15px;\n  overflow: hidden;\n}\n.bg-online[data-v-65be8c7a] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat left center !important;\n  background-size: 6px !important;\n}\n.mileStatus ul li span[data-v-65be8c7a] {\n  display: inline-block;\n  text-align: right;\n  float: right;\n  font-weight: 600;\n  font-size: 16px;\n  color: #00fff1;\n}\nul[data-v-65be8c7a] {\n  margin: 0;\n  padding: 0;\n}\n", ""]);
// Exports
module.exports = exports;
