<template>
  <div class="ant-pro-page-header-wrap" id="cleanerDashboard">
    <div class="ant-pro-page-header-wrap-page-header-warp top-bar">
      <Screenfull targetElementId="cleanerDashboard" class="full-screen" iconColor="#00fff1"/>
      <a @click="handleSetup">设置</a>
    </div>
    <div class="ant-pro-grid-content">
      <monitor-template class="map-style" ref="monitorRef" :mapPageParams="mapPageParams" @mapPageClick="handleMapPageClick"> </monitor-template>
    </div>

    <div class="dashboard-info">
      <!--智驾车辆-->
      <div class="dashboard-item">
        <group-title title="智驾车辆统计" second-title="" />
        <div class="info-content">
          <animate-image prefix="miles" label="今日实时里程" :miles="vehicleTodayForWorkOdom" />
          <vehicle-statis :items="vehicleStatisItems" />
        </div>
      </div>
      <!--传统车辆-->
      <div class="dashboard-item">
        <group-title title="传统车辆统计" second-title="" />
        <div class="info-content">
          <animate-image prefix="miles" label="今日实时里程" :miles="traVehicleTodayForWorkOdom" />
          <vehicle-statis :items="traVehicleStatisItems" />
        </div>
      </div>
      <!--保洁人员工作状态-->
      <div class="dashboard-item">
        <group-title title="保洁人员统计" second-title="" />
        <div class="info-content">
          <cleaner-statis :items="cleanerStatisItems" />
        </div>
      </div>
      <!--保洁人员信息-->
      <div class="dashboard-item" v-if="currentDeviceType === 1">
        <group-title title="保洁人员信息" second-title="" />
        <div class="info-container info-content">
          <animate-image prefix="cleaner" :label="currentCleanerInfo.bindName" :imageWidth="26" />
          <cleaner-info-list :item="currentCleanerInfo" />
        </div>
      </div>
      <!--传统车辆信息-->
      <div class="dashboard-item" v-if="currentDeviceType === 2">
        <group-title title="车辆信息" second-title="" />
        <div class="info-container info-content">
          <animate-image prefix="vehicle" :label="currentTraVehicleInfo.deviceId" :imageWidth="50" />
          <vehicle-info-list :item="currentTraVehicleInfo" />
        </div>
      </div>
      <!-- 智驾车辆信息 -->
      <div class="dashboard-item" v-if="currentDeviceType === 3">
        <group-title title="车辆信息" second-title="" />
        <div class="info-container info-content">
          <animate-image prefix="vehicle" :label="currentVehicleInfo.deviceId" :imageWidth="50" />
          <vehicle-info-list :item="currentVehicleInfo" />
        </div>
      </div>
    </div>
    <config ref="configRef" @callback="handleCallback" />
    <config2 ref="configRef2" @callback="handleCallback2" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MonitorTemplate from '../../monitor/monitorTemplate'
import config from './dark/config'
import GroupTitle from './dark/group-title'
import AnimateImage from './dark/animate-image'
import VehicleStatis from './dark/vehicle-statis'
import CleanerStatis from './dark/cleaner-statis'
import CleanerInfoList from './dark/cleaner-info-list'
import VehicleInfoList from './dark/vehicle-info-list'
import Screenfull from '@/components/Screenfull'
import { queryDashboard } from '@/api/jt808/deviceStatus'
export default {
  components: {
    MonitorTemplate,
    config,
    GroupTitle,
    AnimateImage,
    VehicleStatis,
    CleanerStatis,
    CleanerInfoList,
    VehicleInfoList,
    Screenfull
  },
  data() {
    return {
      cleanerStatisItems: [
        {
          label: '当前选中的保洁人数',
          value: 10,
          src: require('@/assets/images/dashboard/ico-workers1.png'),
          key: 'employeeSelectedNumber',
          visible: true
        },
        {
          label: '其中在线的保洁人数',
          value: 8,
          src: require('@/assets/images/dashboard/ico-workers2.png'),
          key: 'employeeOnline',
          visible: true
        }
      ],

      traVehicleStatisItems: [
        { label: '当前选中的车辆', value: 4, key: 'vehicleSelectedNumber', visible: true },
        { label: '其中在线的车辆', value: 4, bg: 'bg-online', key: 'vehicleOnline', visible: true },
        { label: '昨日出勤总里程（KM）', value: 2391.5, key: 'yesterdayForWorkOdom', visible: true }
      ],

      vehicleStatisItems: [
        { label: '当前选中的车辆', value: 4, key: 'vehicleSelectedNumber', visible: true },
        { label: '其中在线的车辆', value: 4, bg: 'bg-online', key: 'vehicleOnline', visible: true },
        { label: '昨日出勤总里程（KM）', value: 2391.5, key: 'yesterdayForWorkOdom', visible: true }
      ],

      traVehicleTodayForWorkOdom: 0,
      vehicleTodayForWorkOdom: 0,
      currentCleanerInfo: {
        title: ''
      },
      // 传统车辆信息
      currentTraVehicleInfo: {},
      // 智驾车辆信息
      currentVehicleInfo: {},
      // 当前设备类型，1保洁人员2传统车辆3智驾车辆
      currentDeviceType: 3,
      cacheKey: 'cleanerDashboardTree',
      mode: 1,
      cleanerSwitch: {
        showRealLocation: true,
        showSelectedCount: true,
        showOnlineCount: true
      },
      traVehicleSwitch: {
        showRealLocation: true,
        showSelectedCount: true,
        showOnlineCount: true,
        showTodayMiles: true,
        showYestodayMiles: true
      },
      mapPageParams: {
        // 是否更新地图，当打开其它窗口时，则不更新地图
        isUpdateMap: true,
        showHeader: false,
        mapDelayUpdate: true,
        // 此配置控制车辆在地图上的实时分布
        mapDelayUpdateInterval: 1000,
        objectId: 'deviceId',
        // 显示轨迹明细按钮
        showMapTrackDetailButton: false,
        // 地图数据源
        mapDataSource: 'real',
        // 保洁人员当前位置分布及相关人员信息
        objectPoints: [],
        // 是否关闭地图弹窗
        isCloseMapInfoWindow: true,
        // marker 不同设备类型deviceType，对应不同的图标
        markerIconGroup: {
          1: {
            onlineIcon: { url: require('@/assets/images/dashboard/dark/cleaner.png'), size: { width: 40, height: 43 } },
            offlineIcon: { url: require('@/assets/images/dashboard/dark/cleaner-off.png'), size: { width: 40, height: 43 } }
          },
          2: {
            onlineIcon: { url: require('@/assets/images/dashboard/dark/tra-vehicle-online.png'), size: { width: 80, height: 60 } },
            offlineIcon: { url: require('@/assets/images/dashboard/dark/tra-vehicle-offline.png'), size: { width: 80, height: 60 } }
          },
          3: {
            onlineIcon: { url: require('@/assets/images/dashboard/dark/vehicle-online.png'), size: { width: 80, height: 60 } },
            offlineIcon: { url: require('@/assets/images/dashboard/dark/vehicle-offline.png'), size: { width: 80, height: 60 } }
          }
        },
        markerTypeFieldName: 'deviceType',
        mapStyleId: 'ea5a4930f93cbfd98b638770d486f5b0',
        unShowDistance: true,
        unShowTraffic: true
      }
    }
  },
  computed: {
    ...mapState({
      sideCollapsed: (state) => state.app.sideCollapsed
    })
  },
  watch: {
    sideCollapsed(newVal, oldVal) {
      console.log('resize')
      setTimeout(() => {
        this.resize++
      }, 500)
    }
  },
  mounted() {
    console.log('cleaner-dashboard')
    this.handleResize()
    this.getData()
  },
  methods: {
    getData() {
      const params = {
        employeeDeviceIds: ['3302383'],
        vehicleDeviceIds: ['testlam001', '1014676', 'test0003'],
        employeeOnline: 'Y',
        employeeRealLocation: 'Y',
        employeeSelectedNumber: 'Y',
        todayForWorkOdom: 'Y',
        vehicleOnline: 'Y',
        vehicleRealLocation: 'Y',
        vehicleSelectedNumber: 'Y',
        yesterdayForWorkOdom: 'Y'
      }
      console.log('getData')
      queryDashboard(params).then((res) => {
        const data = res.data
        // 1.1保洁人员位置信息
        let cleanerList = data.employeeRealLocationList ? data.employeeRealLocationList : []
        if (cleanerList) {
          cleanerList = cleanerList.map((p) => {
            p.deviceType = 1
            // 模拟修改数据 start
            p.online = 1
            p.coordinates = [80.25518669835809, 41.245709604231784]
            // 模拟修改数据 end
            return p
          })
        }
        // 1.2 保洁人员统计信息
        this.cleanerStatisItems.forEach((p) => {
          if (data[p.key] >= 0) {
            p.value = data[p.key]
            p.visible = true
          } else {
            p.visible = false
          }
        })
        // 1.3重新渲染
        this.cleanerStatisItems = [...this.cleanerStatisItems]

        // 2.1传统车辆
        let traVehicleList = data.vehicleRealLocationList
        if (traVehicleList) {
          traVehicleList = traVehicleList.map((p, idx) => {
            p.deviceType = 2
            // 模拟修改数据 start
            if (idx === 0) {
              p.coordinates = [80.29193207151593, 41.147883372463006]
              p.online = 1
            } else if (idx === 1) {
              p.coordinates = [80.33073984001231, 41.23816528037471]
              p.deviceType = 3
            }
            // 模拟修改数据 end
            return p
          })
        }

        // 2.2 传统车辆统计信息
        this.traVehicleStatisItems.forEach((p) => {
          if (data[p.key] >= 0) {
            p.value = data[p.key]
            p.visible = true
          } else {
            p.visible = false
          }
        })
        this.traVehicleStatisItems = [...this.traVehicleStatisItems]
        this.traVehicleTodayForWorkOdom = data.todayForWorkOdom

        // 智驾车辆统计信息
        this.vehicleStatisItems.forEach((p) => {
          if (data[p.key] >= 0) {
            p.value = data[p.key]
            p.visible = true
          } else {
            p.visible = false
          }
        })
        this.vehicleStatisItems = [...this.vehicleStatisItems]
        this.vehicleTodayForWorkOdom = data.todayForWorkOdom

        this.recentObjectPoints = [...cleanerList, ...traVehicleList].map((p) => {
          return {
            ...p,
            key: p.deviceType,
            lat: p.coordinates[1],
            lng: p.coordinates[0],
            speed: Number(p.speed) / 10
          }
        })

        setTimeout(() => {
          this.$refs.monitorRef && this.$refs.monitorRef.mapUpdateObjectPoints(this.recentObjectPoints)
        }, 1000)
      })
    },
    handleSetup() {
      console.log('handleSetup')
      this.$refs.configRef.handleSetup()
    },
    handleResize() {
      window.onresize = () => {
        this.resize++
        console.log('resize')
      }
    },
    handleMapPageClick(eventName, eventArgs) {
      if (eventName === 'selectObjectInfo') {
        // 地图上选中物体
        this.objectInfo = { ...eventArgs }
        console.log('选中对象', this.objectInfo)
        if (this.objectInfo) {
          this.currentDeviceType = this.objectInfo.deviceType
          if (this.currentDeviceType === 1) {
            this.currentCleanerInfo = { ...this.objectInfo }
          } else if (this.currentDeviceType === 2) {
            this.currentTraVehicleInfo = { ...this.objectInfo }
          } else if (this.currentDeviceType === 3) {
            this.currentVehicleInfo = { ...this.objectInfo }
          }
        }
      }
    },

    handleCallback(localConfig) {
      this.cleanerSwitch = Object.assign(this.cleanerSwitch, localConfig.cleanerSwitch)
      this.traVehicleSwitch = Object.assign(this.traVehicleSwitch, localConfig.traVehicleSwitch)
      // 构建新的请求参数
      // 如果不显示实时位置，则接口请求参数就不传入
      // 重新请求一次接口
      // UI更新
    },

    handleCallback2(localConfig) {
      this.cleanerSwitch = Object.assign(this.cleanerSwitch, localConfig.cleanerSwitch)
      this.traVehicleSwitch = Object.assign(this.traVehicleSwitch, localConfig.traVehicleSwitch)
      // 构建新的请求参数
      // 如果不显示实时位置，则接口请求参数就不传入
      // 重新请求一次接口
      // UI更新
    }
  }
}
</script>
<style lang="less" scoped>
@import './dark/style.css';
.dashboard {
  padding-top: 10px;
}
.map-style {
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}

.top-bar {
  position: relative !important;
  height: 56px;
  width: 100%;
  background-image: url('../../../assets/images/dashboard/header-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  background-size: auto 50px;
  z-index: 100;
  a {
    position: absolute;
    top: 2px;
    right: 10px;
    color: #00fff1;
    display: block;
    padding-left: 20px;
    width: 60px;
    background: url('../../../assets/images/dashboard/ico-setting.png') no-repeat left center;
    background-size: 16px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
  }
}

.info-container {
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  > :first-child {
    margin-left: 30px;
    margin-right: 30px;
  }
  > :last-child {
    flex: 1;
  }
}

.dashboard-info {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 350px;
  position: absolute;
  top: 70px;
  right: 0px;
  .dashboard-item {
    margin-top: 0px;
  }
  .info-content {
    background-color: rgba(13, 21, 21, 0.7);
    -webkit-backdrop-filter: saturate(180%) blur(15px);
    -moz-backdrop-filter: saturate(180%) blur(15px);
    backdrop-filter: saturate(180%) blur(15px);
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.full-screen {
  padding-top: 7px;
  padding-left: 10px;
}

/deep/ .ant-pro-page-header-wrap-page-header-warp {
  background-color: #0a151c;
}
</style>
