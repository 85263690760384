var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ant-pro-page-header-wrap",
      attrs: { id: "cleanerDashboard" },
    },
    [
      _c(
        "div",
        { staticClass: "ant-pro-page-header-wrap-page-header-warp top-bar" },
        [
          _c("Screenfull", {
            staticClass: "full-screen",
            attrs: {
              targetElementId: "cleanerDashboard",
              iconColor: "#00fff1",
            },
          }),
          _c("a", { on: { click: _vm.handleSetup } }, [_vm._v("设置")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ant-pro-grid-content" },
        [
          _c("monitor-template", {
            ref: "monitorRef",
            staticClass: "map-style",
            attrs: { mapPageParams: _vm.mapPageParams },
            on: { mapPageClick: _vm.handleMapPageClick },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "dashboard-info" }, [
        _c(
          "div",
          { staticClass: "dashboard-item" },
          [
            _c("group-title", {
              attrs: { title: "智驾车辆统计", "second-title": "" },
            }),
            _c(
              "div",
              { staticClass: "info-content" },
              [
                _c("animate-image", {
                  attrs: {
                    prefix: "miles",
                    label: "今日实时里程",
                    miles: _vm.vehicleTodayForWorkOdom,
                  },
                }),
                _c("vehicle-statis", {
                  attrs: { items: _vm.vehicleStatisItems },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "dashboard-item" },
          [
            _c("group-title", {
              attrs: { title: "传统车辆统计", "second-title": "" },
            }),
            _c(
              "div",
              { staticClass: "info-content" },
              [
                _c("animate-image", {
                  attrs: {
                    prefix: "miles",
                    label: "今日实时里程",
                    miles: _vm.traVehicleTodayForWorkOdom,
                  },
                }),
                _c("vehicle-statis", {
                  attrs: { items: _vm.traVehicleStatisItems },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "dashboard-item" },
          [
            _c("group-title", {
              attrs: { title: "保洁人员统计", "second-title": "" },
            }),
            _c(
              "div",
              { staticClass: "info-content" },
              [
                _c("cleaner-statis", {
                  attrs: { items: _vm.cleanerStatisItems },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.currentDeviceType === 1
          ? _c(
              "div",
              { staticClass: "dashboard-item" },
              [
                _c("group-title", {
                  attrs: { title: "保洁人员信息", "second-title": "" },
                }),
                _c(
                  "div",
                  { staticClass: "info-container info-content" },
                  [
                    _c("animate-image", {
                      attrs: {
                        prefix: "cleaner",
                        label: _vm.currentCleanerInfo.bindName,
                        imageWidth: 26,
                      },
                    }),
                    _c("cleaner-info-list", {
                      attrs: { item: _vm.currentCleanerInfo },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.currentDeviceType === 2
          ? _c(
              "div",
              { staticClass: "dashboard-item" },
              [
                _c("group-title", {
                  attrs: { title: "车辆信息", "second-title": "" },
                }),
                _c(
                  "div",
                  { staticClass: "info-container info-content" },
                  [
                    _c("animate-image", {
                      attrs: {
                        prefix: "vehicle",
                        label: _vm.currentTraVehicleInfo.deviceId,
                        imageWidth: 50,
                      },
                    }),
                    _c("vehicle-info-list", {
                      attrs: { item: _vm.currentTraVehicleInfo },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.currentDeviceType === 3
          ? _c(
              "div",
              { staticClass: "dashboard-item" },
              [
                _c("group-title", {
                  attrs: { title: "车辆信息", "second-title": "" },
                }),
                _c(
                  "div",
                  { staticClass: "info-container info-content" },
                  [
                    _c("animate-image", {
                      attrs: {
                        prefix: "vehicle",
                        label: _vm.currentVehicleInfo.deviceId,
                        imageWidth: 50,
                      },
                    }),
                    _c("vehicle-info-list", {
                      attrs: { item: _vm.currentVehicleInfo },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("config", { ref: "configRef", on: { callback: _vm.handleCallback } }),
      _c("config2", {
        ref: "configRef2",
        on: { callback: _vm.handleCallback2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }