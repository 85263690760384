<template>
  <div class="mileStatus">
    <ul>
      <li v-for="(item, index) in visilbeItems" :key="index" :class="[item.bg ? item.bg : '']">
        {{ item.label }} <span>{{ item.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {}
    }
  },
  computed: {
    visilbeItems() {
      return this.items.filter((p) => p.visible)
    }
  }
}
</script>

<style lang="less" scoped>
.mileStatus {
  margin-left: 120px;
  margin-top: -10px;
}
.mileStatus ul li {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border-bottom: 1px dotted #01504c;
  padding-right: 30px;
  background: url('../../../../assets/images/dashboard/ico-tit2.png') no-repeat left center;
  background-size: 6px;
  padding-left: 15px;
  overflow: hidden;
}
.bg-online {
  background: url('../../../../assets/images/dashboard/ico-tit3.png') no-repeat left center !important;
  background-size: 6px !important;
}
.mileStatus ul li span {
  display: inline-block;
  text-align: right;
  float: right;
  font-weight: 600;
  font-size: 16px;
  color: #00fff1;
}
ul {
  margin: 0;
  padding: 0;
}
</style>
